import React, { useEffect } from 'react'
import { Layout } from '../../components/AppLayout'
import { AppCompanyNewsBanner } from '../../components/AppCompanyNewsBanner'
// import { AppCompanyReleases } from '../../components/AppCompanyReleases'
import { AppJeffHomeBanner } from '../../components/AppJeffHomeBanner'
import { AppCathyHomeBanner } from '../../components/AppCathyHomeBanner'
import AppCarouselSlider from '../../components/AppCarouselSlider'
import Scroll from 'react-scroll'
import { RDHelmet } from '../../components/RDHelmet'
import { AppWorkwearOutfittersBanner } from '../../components/AppWorkwearOutfittersBanner'
import { AppHomeCustomersReview } from '../../components/AppHomeCustomersReview'
import { AppCustomersReviewSection } from '../../components/AppCustomersReviewSection'
var scroll = Scroll.animateScroll

const scrollData = {
  TotalSolution: 700,
  Security: 1000,
  ExceptionalService: 700,
  TailoredFit: 700,
  Experience: 1000,
  Quality: 1000,
}

const scrollToIndex = (location: string) => {
  const item = Object.entries(scrollData).find((item) => item[0] == location)
  if (item && item[1]) {
    scroll.scrollTo(item[1])
  }
}

const Customers = (props: any) => {
  const schemaMarkup = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Raindrop Systems Inc',
    alternateName: 'Raindrop Systems Inc',
    url: 'https://raindrop.com/',
    logo: 'https://storage.googleapis.com/raindroppublic/website_data/raindrop_icon.svg',
    description:
      'Raindrop is the modern AI-Powered Source-to-Pay SaaS Enterprise Spend Management Full-Suite Modular Platform. Raindrop offers an AI-enabled enterprise cloud based spend management software focused on cost savings. Get started with free tools, and upgrade as you grow.',
    image: 'https://storage.googleapis.com/raindroppublic/website_data/OurServices.jpg',
    email: 'info@raindrop.com',
    contactPoint: [
      { '@type': 'ContactPoint', telephone: '408-352-5162', contactType: 'Business', areaServed: 'United States' },
    ],
    address: {
      '@type': 'Corporate',
      addressLocality: ' 226 Airport Parkway Suite 250',
      addressRegion: ' San Jose, California 95110',
      postalCode: '95110',
      addressCountry: 'United States',
    },
    sameAs: [
      'https://www.facebook.com/Raindrop-Systems-Inc-103810868375793',
      'https://twitter.com/saas_raindrop',
      'https://www.instagram.com/raindrop_spendmanagement/?igshid=1mn6sbd70aybs',
      'https://www.linkedin.com/company/raindropsystemsinc/',
    ],
  }
  useEffect(() => {
    if (props.location.state && props.location.state.scroll) {
      scrollToIndex(props.location.state.scroll)
    }
  }, [])
  return (
    <Layout>
      <RDHelmet
        subtitle={'Customers'}
        contentDescription="Learn how our customers succeed using Raindrop's innovative solutions and services."
        contentKeywords="content='Customers, Success stories, Client testimonials, Case studies, Business growth, Customer satisfaction, Industry leaders, Solutions impact, Raindrop results, Client partnerships'"
        contentOG="AI Powered Source-to-Pay SaaS Platform"
        contentOgDescription="Learn how our customers succeed using Raindrop's innovative solutions and services."
        contentOgUrl="https://raindrop.com/about/Customers"
        schemaMarkup={schemaMarkup}
      />
      <AppCompanyNewsBanner />
      <AppCarouselSlider />
      <AppHomeCustomersReview />
      <AppCustomersReviewSection />
      <AppWorkwearOutfittersBanner />
      <AppCathyHomeBanner />
      <AppJeffHomeBanner />
      {/* <AppCompanyReleases /> */}
    </Layout>
  )
}

export default Customers
